
import { Component, Vue } from "vue-property-decorator";
import moment from "moment-timezone";

@Component({
  components: {
    Expense: () =>
      import("@/app/modules/expenses/components/ExpenseComponent.vue")
  }
})
export default class ExpensesPage extends Vue {
  periods = [
    {
      id: 0,
      period: `${this.$t("EREPORTER.FILTER.ALL")} ${this.$t(
        "EREPORTER.TASK.EXPENSES"
      )}`,
      start: moment()
        .subtract(100, "years")
        .startOf("isoWeek"),
      end: moment()
        .add(100, "years")
        .endOf("isoWeek")
    },
    {
      id: 1,
      period: this.$t("EREPORTER.FILTER.CURRENT_WEEK"),
      start: moment()
        .add(0, "week")
        .startOf("isoWeek"),
      end: moment()
        .add(0, "week")
        .endOf("isoWeek")
    },
    {
      id: 2,
      period: this.$t("EREPORTER.FILTER.PREVIOUS_WEEK"),
      start: moment()
        .subtract(1, "week")
        .startOf("isoWeek"),
      end: moment()
        .subtract(1, "week")
        .endOf("isoWeek")
    },
    {
      id: 3,
      period: this.$t("EREPORTER.FILTER.CURRENT_MONTH"),
      start: moment()
        .add(0, "month")
        .startOf("month"),
      end: moment()
        .add(0, "month")
        .endOf("month")
    },
    {
      id: 4,
      period: this.$t("EREPORTER.FILTER.PREVIOUS_MONTH"),
      start: moment()
        .subtract(1, "month")
        .startOf("month"),
      end: moment()
        .subtract(1, "month")
        .endOf("month")
    }
  ];

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get periodId() {
    return this.$store.getters["expenses/period"];
  }

  set periodId(value) {
    this.$store.commit("expenses/setPeriod", value);
  }

  get expensesData() {
    return this.$store.getters["expenses/data"];
  }

  get expensesDataPeriodFiltered() {
    const unsorted = this.expensesData?.filter(expense => {
      const period = this.periods[this.periodId];
      return moment(expense.date).isBetween(
        period.start,
        period.end,
        undefined,
        "[]"
      );
    });

    return unsorted
      ?.slice()
      .sort((a, b) => moment(b.date).unix() - moment(a.date).unix());
  }

  mounted() {
    moment.tz.setDefault();
  }

  created() {
    this.$store.dispatch("expenses/fetchAll").then(({ data }) => {
      this.$store.commit("expenses/setData", data.data);
    });
  }
}
